<template>
  <div style="display: flex">
    <div id="form" v-if="form" class="padding">
      <p class="h3-title">{{ form.car_make }}, {{ form.car_model }}</p>
      <div class="flex-row margin-top-05">
        <div>
          <p class="h5">Odometer: {{ form.car_mileage }} kms</p>
          <p class="h5">Rego: {{ form.car_registration_number }}</p>
        </div>
        <div class="margin-left">
          <p class="h5">Intention Of Trade: {{ getTradedPurposeString(form) }}</p>
          <p class="h5">
            {{ currentDealer && currentDealer.is_manager ? "Appraisal value sent to sales staff" : "Appraisal Value" }}:
            {{ form.sales_value ? "$" + form.sales_value : "N/A" }}
          </p>
        </div>
        <div class="margin-left">
          <p class="h5">Retail Value: {{ form.retail_value }}</p>
          <div>
            <p class="h5" v-for="(integration, i) in getIntegrationInformationData()" v-bind:key="i">
              {{ integration.title }}: {{ integration.content }}
            </p>
          </div>
        </div>
      </div>
      <div class="width-100p" v-if="form.offers">
        <offer-table :form="form" :small="true" />
      </div>
      <div class="form-content-container">
        <div class="margin-top" v-for="(section, j) in formData()" v-bind:key="j">
          <p class="h4">
            {{ section.title ? section.title : "&nbsp;" }}
          </p>
          <table class="form-table-small">
            <tr v-for="(item, k) in section.data.filter(dict => !dict.isHidden)" v-bind:key="k">
              <td class="heading-td">{{ item.title }}</td>
              <td class="content-td">
                <p>{{ (item.content ? item.content : form[item.key]) || "—" }}</p>
              </td>
            </tr>
          </table>
        </div>
        <div class="margin-top">
          <p class="h4">Body Damage / Costs</p>
          <div class="flex-row margin-top">
            <div v-if="form.damage_maps && form.damage_maps.length">
              <damage-map :form="form" :showLegend="false" />
            </div>
          </div>
          <p
            class="body-3"
            v-if="
              !(form.body_damages && form.body_damages.length > 0) &&
              !(form.damage_maps && form.damage_maps.length) &&
              !(evalDamageData(form).length > 0)
            "
          >
            No Body Damage or Costs
          </p>
          <table class="form-table-small">
            <tr v-for="damage in form.body_damages" v-bind:key="damage.url">
              <td class="heading-td">{{ damage.description }}</td>
              <td class="content-td">{{ damage.cost ? "$" + damage.cost : "" }}</td>
              <td class="content-td">
                {{ damage.is_mechanical != null ? (damage.is_mechanical ? "Mechanical" : "Body") : "" }}
              </td>
            </tr>
            <tr v-for="damage in evalDamageData(form)" v-bind:key="damage">
              <td class="heading-td">{{ damage.title }}</td>
              <td class="content-td">{{ damage.content }}</td>
              <td></td>
            </tr>
          </table>
          <div
            v-if="form.general_photos && form.general_photos.length"
            class="margin-top photo-container-container-big"
          >
            <img v-for="photo in form.general_photos" v-bind:key="photo.url" :src="photo.photo" class="photo" />
          </div>
        </div>
        <div class="bordered padding flex-column margin-top-05 columns-2">
          <p class="body-3 text-center">TO BE COMPLETED ON ACQUISITION OF TRADE</p>
          <div class="flex-row margin-top-05">
            <div class="flex-grow flex-row">
              <p class="body-3-bold">Date Traded</p>
              <div class="margin-left margin-right text-prompt"></div>
            </div>
            <div class="flex-grow flex-row">
              <p class="body-3-bold">Time Traded</p>
              <div class="margin-left margin-right text-prompt"></div>
            </div>
            <div class="flex-grow flex-row">
              <p class="body-3-bold">Odometer</p>
              <div class="margin-left margin-right text-prompt"></div>
            </div>
            <div class="flex-grow flex-row">
              <p class="body-3-bold">Net Price</p>
              <div class="margin-left margin-right text-prompt"></div>
            </div>
          </div>
          <div class="flex-row margin-top-05">
            <div class="flex-grow flex-row">
              <p class="body-3-bold">Accepted By</p>
              <div class="margin-left margin-right text-prompt"></div>
            </div>
            <div class="flex-grow flex-row">
              <p class="body-3-bold">From Deal #</p>
              <div class="margin-left margin-right text-prompt"></div>
            </div>
            <div class="flex-grow flex-row">
              <p class="body-3-bold">Stock Number</p>
              <div class="margin-left margin-right text-prompt"></div>
            </div>
            <div class="flex-grow flex-row">
              <p class="body-3-bold">Gross Price</p>
              <div class="margin-left margin-right text-prompt"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <error-modal v-if="error" :error="error" @cancel="error = null" />
  </div>
</template>

<script>
import ErrorModal from "../../../components/Modals/v2/ErrorModal.vue";
import {
  tradedPurposeString,
  dealershipData,
  customerInformationData,
  carInformationData,
  specObjectData,
  evalDamageData,
  integrationInformationData,
  damageTypes
} from "../../../helpers/v2/formHelpers.js";
import { fetchForm } from "../../../api/Form.js";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";

import OfferTable from "../../../components/Views/v2/OfferTable/OfferTable.vue";
import DamageMap from "../../../components/Views/v2/Form/DamageMap.vue";

export default {
  components: {
    OfferTable,
    DamageMap,
    ErrorModal
  },
  name: "FormPDF",
  data: function () {
    return {
      form: null,
      error: null,
      formLoaded: false,
      loadCount: 0
    };
  },
  methods: {
    loadData: function () {
      this.form = this.getForm(this.$route.params.id);
      fetchForm(this.$route.params.id)
        .then(form => {
          this.formLoaded = true;
          this.form = form;
          if (this.form.offers) {
            this.form.offers = this.form.offers.filter(offer => {
              return offer.is_final;
            });
          }
          if (!this.forFleet) {
            this.form.general_photos = this.form.general_photos.filter(gp => {
              return gp.is_full_body || gp.is_vin;
            });
          }
          let bodyDamageLength = this.form.body_damages.length;
          this.form.body_damages = this.form.body_damages.slice(0, 6);
          if (bodyDamageLength > 6) {
            this.form.body_damages.push({
              description: "More"
            });
          }

          let app = this;
          setTimeout(() => {
            app.$nextTick(() => {
              if (this.$route.params.noprint == "" || this.$route.params.noprint === undefined) {
                window.print();
              }
            });
          }, 2000);
        })
        .catch(error => {
          this.error = error;
        });
    },
    evalDamageData: function (form) {
      return evalDamageData(form);
    },
    formData: function () {
      let splitCarInfo = this.splitSection(carInformationData(this.form));
      let specObjData = specObjectData(this.form);
      let specObjDataLength = specObjData.data.length;
      specObjData.data = specObjData.data.slice(0, 8);
      if (specObjDataLength > 8) {
        specObjData.data.push({ title: "More", content: "Yes" });
      }

      var dealershipDataDict = dealershipData(this.form, true);
      let unneededKeys = ["Appraisal Duration"];
      dealershipDataDict.data = dealershipDataDict.data.filter(dict => !unneededKeys.includes(dict.title));

      return [dealershipDataDict, customerInformationData(), specObjData, splitCarInfo[0], splitCarInfo[1]];
    },
    getTradedPurposeString: function () {
      return tradedPurposeString(this.form.traded_purpose);
    },
    splitSection: function (section) {
      let section1 = _.cloneDeep(section);
      let section2 = _.cloneDeep(section);
      var half_length = Math.ceil(section.data.length / 2);
      section1.data = section1.data.splice(0, half_length);
      section2.data = section2.data.splice(half_length, section.data.length);
      section2.title = null;

      return [section1, section2];
    },
    calculateIndexForFormData: function () {
      var indeces = [];
      for (var i = 0; i < this.formData().length; i++) {
        indeces.push(i);
        if (!this.formData()[i].wide) {
          if (this.formData()[i + 1] && !this.formData()[i + 1].wide) {
            i++;
          }
        }
      }
      return indeces;
    },
    getDamgeDataForIndex: function (i) {
      if (!this.form.damage_maps) {
        return;
      }
      const coordX = i % 10;
      const coordY = Math.abs(Math.floor(i / 10) - 5);
      const damageMapObject = this.form.damage_maps.filter(dmo => {
        return dmo.area_x == coordY && dmo.area_y == coordX;
      });
      if (damageMapObject.length > 0) {
        return {
          colour: damageTypes[damageMapObject[0].code].colour,
          photo: damageTypes[damageMapObject[0].code].photo
        };
      }
      return {
        colour: "transparent",
        photo: null
      };
    },
    sortOffer: function (a, b) {
      var offerAValue = 0;
      var offerBValue = 0;

      if (a.adjusted_value) {
        offerAValue = a.adjusted_value;
      } else if (a.price) {
        offerAValue = a.price;
      }

      if (b.adjusted_value) {
        offerBValue = b.adjusted_value;
      } else if (b.price) {
        offerBValue = b.price;
      }

      return offerBValue - offerAValue;
    },
    getIntegrationInformationData: function () {
      return integrationInformationData(this.form).data.filter(integration => {
        return integration.content != null && integration.content != "";
      });
    }
  },
  computed: {
    damageTypes: function () {
      return damageTypes;
    },
    forFleet: function () {
      return this.$route.name == "FleetPDF";
    },
    ...mapGetters({
      getForm: "formStore/getForm"
    }),
    ...mapState({
      currentDealer: state => state.dealershipStore.currentDealer
    })
  },
  watch: {
    $route: function () {
      if (this.$route.name == "Form") {
        this.loadData();
      }
    }
  },
  mounted: function () {
    const app = this;
    this.$nextTick(() => {
      app.loadData();
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

@page {
  size: A4;
  margin: 0;
}
@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
  }

  .pagebreak {
    page-break-before: always;
  }
}

.form-content-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 16px;
}

.photo-container-container-big {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 12px;

  .photo {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.text-prompt {
  border-bottom: 1px solid black;
  height: 1px;
  margin-top: auto;
  flex-grow: 1;
}

.columns-2 {
  grid-column: 1/4;
}
</style>
